
export enum NodeConfigOptionsConstants {

	one_vcpu = "one_vcpu",
	two_vcpu = "two_vcpu",
	two_vcpu_eight_gb_expandible = "two_vcpu_eight_gb_expandible",
	two_vcpu_four_gb_expandible = "two_vcpu_four_gb_expandible",

}

export enum PaymentFrequencyEnumConstants {

	monthly = "monthly",
	six_months = "six_months",
	three_months = "three_months",
	yearly = "yearly",

}

export enum DeploymentTypeConstants {

	aws_cloud = "aws_cloud",
	azure_cloud = "azure_cloud",
	devum_cloud = "devum_cloud",
	docker = "docker",
	google_cloud = "google_cloud",
	private_cloud = "private_cloud",

}

export enum CustomerProfileUiEntitiesEnumConstants {

	CustomerProfileUI = "CustomerProfileUI",
	InitialStateUI = "InitialStateUI",
	NoProfileUI = "NoProfileUI",

}

export enum BillingPeriodEnumConstants {

	MSDeploymentsUI = "MSDeploymentsUI",
	MSInitialDENDeploymentUI = "MSInitialDENDeploymentUI",
	month = "month",
	year = "year",

}

export enum ServiceStatusEnumConstants {

	active = "active",
	inactive = "inactive",
	inprogress = "inprogress",

}

export enum DevumPlanEnumConstants {

	Enterprise = "Enterprise",
	Free = "Free",
	Premium = "Premium",
	Professional = "Professional",
	DevumHosted = "DevumHosted"

}

export enum MemoryTypeConstants {

	Gi = "Gi",
	Mi = "Mi",

}

export enum CurrencyTypeEnumConstants {

	inr = "inr",
	usd = "usd",

}

export enum EnvironmentTypeConstants {

	dev = "dev",
	prod = "prod",
	qa = "qa",
	uat = "uat",

}

export enum LeadSourceEnumConstants {

	facebook = "facebook",
	google = "google",
	other = "other",
	salesteam = "salesteam",

}

export enum PaymentMethodEnumConstants {

	credit_card = "credit_card",
	debit_card = "debit_card",
	netbanking = "netbanking",

}

export enum NumberOfNodesConstants {

	eight = "eight",
	five = "five",
	four = "four",
	nine = "nine",
	one = "one",
	seven = "seven",
	shared = "shared",
	six = "six",
	ten = "ten",
	three = "three",
	two = "two",

}

export enum ServiceTypeConstants {

	core = "core",
	custom = "custom",

}

export enum DeploymentUIEntitiesEnumConstants {

	BEDeploymentUI = "BEDeploymentUI",
	DenDeploymentsUI = "DenDeploymentsUI",
	InitialDENDeploymentUI = "InitialDENDeploymentUI",

}

export enum KuberneterServiceTypeConstants {

	ClusterIP = "ClusterIP",
	LoadBalancer = "LoadBalancer",
	NodePort = "NodePort",

}

export enum MicroServiceOptionsConstants {

	basic = "basic",
	premium = "premium",

}

export enum CPUTypeConstants {

	m = "m",
	vCPU = "vCPU",

}

export enum PeriodOptionsConstants {

	five_minutes = "five_minutes",
	five_seconds = "five_seconds",
	no_limit = "no limit",
	one_minute = "one_minute",
	thirty_seconds = "thirty_seconds",
	two_minutes = "two_minutes",

}

export enum DevumTimeUnitTypeConstants {

	days = "days",
	hours = "hours",
	milliseconds = "milliseconds",
	minutes = "minutes",
	month = "month",
	seconds = "seconds",
	week = "week",

}
